import React from "react";
import { RiChatSmile3Line } from "react-icons/ri";
import TestimonialCard from "../ui/TestimonialCard";
import Title from "../ui/Title";
import { useTranslation } from "gatsby-plugin-react-i18next";

const TestimonialsWrapper = () => {
    const { t } = useTranslation();

    return (
        <div className="mx-[6%]">
            <Title variant="md">
                <div className="flex items-center gap-4">
                    <RiChatSmile3Line {...{ style: { transform: "scale(-1, 1)" } }} />
                    <span>{t("testimonials.sectionTitle")}</span>
                </div>
            </Title>
            <div className="container mx-auto gap-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-8 mb-10">
                {t("testimonials.testimonials", { returnObjects: true }).map((testimonial, index) => (
                    <TestimonialCard
                        key={index}
                        full={testimonial.fullStars}
                        half={testimonial.halfStars}
                        title={testimonial.title}
                        author={testimonial.author}
                        review={testimonial.review}
                    />
                ))}
                <TestimonialCard
                    full={5}
                    half={0}
                    isEnd
                />
            </div>
        </div>
    );
}

export default TestimonialsWrapper;
