import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useMobile } from "../../hooks/hooks.js";
import { WidthContainer } from "../WidthContainer";
import equipe from "./equipe.webp";

const Equipe = () => {
    const { mobile } = useMobile();
    const { t } = useTranslation();

    return (
        <div id="Equipe" style={{ padding: "72px 0 196px 0", color: "white" }}>
            <WidthContainer center>
                <div style={{
                    display: "grid",
                    gridTemplate: mobile ? `"a" "b"` : `"a b" / 1fr .75fr`,
                    placeContent: "start",
                    gap: mobile ? "40px" : "60px",
                    fontSize: mobile ? "16px" : "24px",
                    zIndex: "3",
                }}>
                    <div style={{ gridArea: "a" }}>
                        <h2 style={{
                            placeSelf: "center start",
                            fontSize: mobile ? "20px" : "36px",
                            fontWeight: 600,
                            margin: "0px 0px 48px 0px",
                        }}>
                            {t("team.title")}
                        </h2>
                        {t("team.description1")}
                        <br /><br />
                        {t("team.description2")}
                        <br /><br />
                        {t("team.description3")}
                    </div>
                    <div style={{
                        gridArea: "b",
                        textAlign: "center",
                        fontWeight: 600
                    }}>
                        <img src={equipe} alt="" />
                        {t("team.teamCaption")}
                    </div>
                </div>
            </WidthContainer>
        </div>
    );
}

export { Equipe };
