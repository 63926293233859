import React from "react";
import { FaUserCircle } from "react-icons/fa";
import { TiStarFullOutline, TiStarHalfOutline } from "react-icons/ti";
import { ButtonStore } from "./ButtonStore";
import {useTranslation} from "react-i18next";

interface cardProps {
    full:number;
    half:number;
    title?:string;
    author?:string;
    review?:string;
    isEnd?:boolean;
}

const TestimonialCard = ({full,half,title,author,review,isEnd}:cardProps) => {
    const {t} = useTranslation()
    return (
        <div className="bg-white rounded-[40px] p-8 flex flex-col items-center gap-8">
            {isEnd ? (
                <>
                    <div className="flex justify-center">
                        { Array(full).fill("").map((item, i) => (<TiStarFullOutline className="w-6 h-auto" fill="#FAB357" key={i}/>)) }
                        {!!half && <TiStarHalfOutline className="w-6 h-auto" fill="#FAB357" />}
                    </div>
                    <h6 className="text-xl text-ink text-center">{t('testimonials.viewReviews')}</h6>
                    <span className="text-4xl text-ink text-center">🙂</span>
                    <div className="flex flex-col w-full gap-4">
                        <ButtonStore variant="apple"/>
                        <ButtonStore variant="playstore"/>
                    </div>
                </>
            ) : (
                <>
                    <div className="flex justify-center">
                        { Array(full).fill("").map((item, i) => (<TiStarFullOutline className="w-6 h-auto" fill="#FAB357" key={i}/>)) }
                        {!!half && <TiStarHalfOutline className="w-6 h-auto" fill="#FAB357" />}
                    </div>
                    <h6 className="text-xl text-ink text-center">{title}</h6>
                    <div className="flex justify-center items-center gap-4">
                        <FaUserCircle className="w-6 h-auto" />
                        <span className="font-light text-xl text-ink">{author}</span>
                    </div>
                    <p className="font-light text-ink">{review}</p>
                </>
            ) }
        </div>
);
}

export default TestimonialCard
