import React, {useEffect} from "react"
import { Background } from "./Background/Background"
import { Background2 } from "./Background/Background2"
import { BackgroundSection } from './Background/BackgroundSection'
import { Equipe } from "./Equipe/Equipe"
import { Header } from "./Header"
import Banner from "./ui/Banner"

import { SvgCare } from './icons/care'
import { SvgQuality2 } from './icons/quality2'
import { FaRegHeart } from "react-icons/fa";
import { RiSurroundSoundLine } from "react-icons/ri";
import Title from "./ui/Title"
import { WidthContainer } from "./WidthContainer"
import Faq from "./ui/Faq"
import { Hero } from "./ui/Hero"
import { ButtonStore } from "./ui/ButtonStore"
import ResultsWrapper from "./sections/ResultsWrapper"
import ThemeWrapper from "./sections/ThemesWrapper"
import TestimonialsWrapper from "./sections/TestimonialsWrapper"
import OffersWrapper from "./sections/OffersWrapper"
import Footer from "./ui/Footer"
import firebase from "gatsby-plugin-firebase"
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics"
import {useTranslation} from "react-i18next";

const Home = ({ location }) => {
    const { t } = useTranslation();

    useEffect(() => {
        if(!firebase) {
            return;
        }
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed)
        firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_home_page_viewed)
    }, [firebase])

    return (
        <div id="background" style={{ placeItems: "center", background: "#102543", height: "100%", color: "#102543" }}>
            <Header location={location} />
            <Hero
                title={t('home.heroTitle')}
                subtitle={t('home.heroSubtitle')}
                img="/images/hero/poster1.webp"
                showcaseImg="/images/hero/appscreenshots.webp"
                video="/images/hero/14-900-crf16-veryslow-pixfmtyuv420.mp4"
            >
                <ButtonStore style={{minWidth: '215px'}} variant="apple"/>
                <ButtonStore style={{minWidth: '215px'}} variant="playstore"/>
            </Hero>
            <Background>
                <WidthContainer center>
                    <div className="w-full md:w-4/5">
                        <Title addStyle="w-full md:w-4/5 mt-16 mb-8" variant="md">{t('home.benefitHypnosisTitle')}</Title>
                        <Faq
                            items={[
                                {
                                    title: t('home.hypnosisWhatIsItTitle'),
                                    desc: t('home.hypnosisWhatIsItDesc')
                                }
                            ]}
                        />
                    </div>
                </WidthContainer>
                <Banner
                    title={t('home.therapeuticHypnosisSessionsTitle')}
                    ctaAnchor={t('home.testExperienceHappiz')}
                    ctaAction="https://happiz.onelink.me/5ePS/e6b33nm1"
                    img="/images/benefits/b1.webp"
                    ctaIcon={<FaRegHeart />}
                >
                    <p>{t('home.hypnosisApplicationForms')}</p>
                </Banner>
                <Banner
                    title={t('home.highQualityHypnosisSessionsTitle')}
                    ctaAnchor={t('home.testExperienceHappiz')}
                    ctaAction="https://happiz.onelink.me/5ePS/e6b33nm1"
                    img="/images/benefits/b2.webp"
                    ctaIcon={<SvgQuality2 />}
                    isReverse
                >
                    <p>{t('home.highQualityHypnosisSessionsDesc')}</p>
                </Banner>
                <Banner
                    title={t('home.cinemaLikeSoundSessionsTitle')}
                    ctaAnchor={t('home.testExperienceHappiz')}
                    ctaAction="https://happiz.onelink.me/5ePS/e6b33nm1"
                    img="/images/benefits/surround.webp"
                    ctaIcon={<RiSurroundSoundLine />}
                >
                    <p>{t('home.cinemaLikeSoundSessionsDesc')}</p>
                </Banner>
                <Banner
                    title={t('home.safeHypnosisExperiencesTitle')}
                    ctaAnchor={t('home.testExperienceHappiz')}
                    ctaAction="https://happiz.onelink.me/5ePS/e6b33nm1"
                    img="/images/benefits/b3.webp"
                    ctaIcon={<SvgCare />}
                    isReverse
                >
                    <p>{t('home.safeHypnosisExperiencesDesc')}</p>
                </Banner>

                <BackgroundSection bottomBenefits>
                    <Banner
                        title={t('home.personalizedProgramsTitle')}
                        ctaAnchor={t('home.testExperienceHappiz')}
                        ctaAction="https://happiz.onelink.me/5ePS/e6b33nm1"
                        img="/images/benefits/appComplete.webp"
                        ctaIcon={<SvgCare />}
                        isReverse
                    >
                        <p>{t('home.personalizedProgramsDesc')}</p>
                    </Banner>
                </BackgroundSection>
            </Background>
            <Background2>
                <ResultsWrapper />
                <BackgroundSection themeTemoignages>
                    <TestimonialsWrapper />
                </BackgroundSection>
                <BackgroundSection equipe>
                    <Equipe />
                </BackgroundSection>
            </Background2>
            <Footer />
        </div>
    );
};

export { Home };
