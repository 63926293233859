import React from "react";
import Title from "./Title";

interface itemProps {
    id: number;
    title: string;
    desc: string;
    active: boolean;
    setActive:Function;
}

const FaqItem = ({id,title,desc,active,setActive}:itemProps) => {
    return (
        <div onClick={()=>{setActive(active ? 1000 : id)}} className="bg-white rounded-[30px] px-4 py-8 mb-4 cursor-pointer">
                <div className="flex items-center">
                    <svg className={`w-[60px] transition-all ${active ? "rotate-90" : "rotate-0"}`} stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z"></path></svg>
                    <Title variant="sm">{title}</Title>
                </div>
                <p className={`px-8 overflow-hidden text-ink font-light text-lg m-0 transition-all ${active ? "h-full pt-4 opacity-100" : "h-0 opacity-0"}`}>{desc}</p>
        </div>
    )
}

export default FaqItem