import * as React from 'react';
// import "./quality2.css";

// https://thenounproject.com/icon/quality-3152755/
const SvgQuality2 = ({ }) => {

    return (
        <svg width="100%" height="100%" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
            <g fill="currentColor">
                <path d="m531.12 392.91c0-0.96484-0.48438-1.9336-0.96484-2.418v-0.48438l-47.844-86.496c-1.9336-2.8984-4.832-4.832-8.6992-4.832h-195.23-1.9336-0.48438-0.48438c-0.48438 0-0.48438 0.48438-0.96484 0.48438 0 0-0.48438 0-0.48438 0.48438-0.48438 0.48438-1.4492 0.96484-1.9336 1.4492-0.48437 0.48438-0.96484 0.96484-1.4492 1.4492v0.48438l-47.84 86.984v0.48438c-0.48438 0.96484-0.96484 1.4492-0.96484 2.418v1.4492 0.96484 0.48437c0 0.96484 0.48438 1.9336 0.48438 2.418v0.48438c0.48438 0.96484 0.96484 1.4492 1.4492 1.9336v0.48438l145.94 159.47 1.4492 1.4492s0.48438 0 0.48438 0.48438c0.48438 0.48438 0.96484 0.48438 1.9336 0.96484h0.48438c0.96484 0 1.4492 0.48438 2.418 0.48438h1.9336 0.48438c0.48438 0 0.48438 0 0.96484-0.48438 0.48438 0 0.48438-0.48438 0.96484-0.48438 0.48438 0 0.48438-0.48438 0.96484-0.48438 0 0 0.48437-0.48438 0.48437-0.48438 0.48438-0.48438 0.48438-0.48438 0.96484-0.96484l145.94-159.47v-0.48438c0.48438-0.48438 0.96484-1.4492 1.4492-1.9336v-0.48438c0.48438-0.96484 0.48438-1.4492 0.48438-2.418v-0.48438-0.96484c0.47656-0.48828 0.47656-0.96875-0.003906-1.9375zm-71.039-74.902-26.578 66.203-41.559-66.203zm-48.324 67.656h-72.004l35.762-57.508zm-52.191-67.656-41.074 65.723-26.094-65.723zm-82.633 13.047 21.746 54.605h-51.707zm-24.648 73.938h54.125l41.559 104.38zm123.71 123.23-48.809-122.26 0.48438-0.96484h96.164l0.48438 0.96484zm28.512-18.844 41.559-104.38h53.641zm49.293-123.71 21.746-54.125 29.961 54.125z" className="svg-quality2-elem-1"></path>
                <path d="m376 273.55c5.3164 0 9.6641-4.3477 9.6641-9.6641v-66.688c0-5.3164-4.3477-9.6641-9.6641-9.6641s-9.6641 4.3477-9.6641 9.6641v66.688c0 5.3125 4.3516 9.6641 9.6641 9.6641z" className="svg-quality2-elem-2"></path>
                <path d="m578 236.83c-3.8672-3.8672-9.6641-3.8672-13.531-0.48438l-49.293 46.875c-3.8672 3.8672-3.8672 9.6641-0.48438 13.531 1.9336 1.9336 4.3477 2.8984 6.7656 2.8984s4.832-0.96484 6.7656-2.8984l49.293-46.875c3.8672-2.8984 3.8672-9.1836 0.48438-13.047z" className="svg-quality2-elem-3"></path>
                <path d="m230.06 300.13c2.418 0 4.832-0.96484 6.7656-2.8984 3.8672-3.8672 3.3828-10.148-0.48438-13.531l-49.293-46.875c-3.8672-3.8672-10.148-3.3828-13.531 0.48438-3.8672 3.8672-3.3828 10.148 0.48438 13.531l49.293 46.875c1.9336 1.9297 4.3477 2.4141 6.7656 2.4141z" className="svg-quality2-elem-4"></path>
            </g>
        </svg>
    )
}

export { SvgQuality2 }

