import React, {useState} from "react";
import FaqItem from "./FaqItem";

interface faqProps {
    items: {title:string,desc:string}[];
}

const Faq = ({items}:faqProps) => {
    const [activeItem,setActiveItem] = useState<number>(0);
    return (
        <div>
            {items.map((item,index) => (
                <FaqItem
                    title={item.title}
                    desc={item.desc}
                    active={index === activeItem}
                    key={item.title}
                    id={index}
                    setActive={setActiveItem}
                />
            ))}
        </div>
    )
}

export default Faq;