import React, {useEffect} from "react"
import { AppWrapper } from "../components/AppWrapper"
import { Home } from "../components/Home"
import firebase from "gatsby-plugin-firebase"
import {graphql} from "gatsby";

export const LocalizationQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function IndexPage({ location, data }) {

  useEffect(() => {
    if (!firebase) {
      return;
    }
    console.log(firebase)
    firebase.analytics()
  }, [firebase]);

  return (
    <AppWrapper>
      <Home {...{ location: location.pathname }}/>
    </AppWrapper>
  )
}
