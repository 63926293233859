import * as React from "react"



const SVGWave2 = (props) => {
    const p1 = { x: 0, y: 20 }
    const c1 = { x: 100, y: -15 }
    const c2 = { x: 300, y: 0 }
    const p2 = { x: 500, y: 75 }
    const p3 = { x: 500, y: 100 } // close path
    const p4 = { x: 0, y: 100 } // close path
    return (
        <svg
            // height="100%"
            width="100%"
            viewBox="0 0 500 100" xmlns="http://www.w3.org/2000/svg" {...props}
            fontSize="6px"
        >
            <path
                d={`
                    M ${p1.x} ${p1.y - 5} 
                    C ${c1.x} ${c1.y} ${c2.x} ${c2.y} 
                    ${p2.x} ${p2.y + 5}
                    L ${p3.x} ${p3.y}
                    ${p4.x} ${p4.y}
                    `}
                // fill="#1A66D8"
                fill="#E0E7F2"
            />
            <path
                d={`
                    M ${p1.x} ${p1.y + 10} 
                    C ${c1.x} ${c1.y + 5} ${c2.x - 5} ${c2.y + 5} 
                    ${p2.x} ${p2.y + 10}
                    L ${p3.x} ${p3.y}
                    ${p4.x} ${p4.y}
                    `}
                // fill="#EEFF00"
                fill="#C5D3E8"
            // fill="#E0E7F2"
            />

            <path
                d={`
                    M ${p1.x} ${p1.y + 25} 
                    C ${c1.x} ${c1.y + 10} ${c2.x} ${c2.y + 10} 
                    ${p2.x} ${p2.y + 15}
                    L ${p3.x} ${p3.y}
                    ${p4.x} ${p4.y}
                    `}
                fill="#9EB6D8"
            />


        </svg>
    )
}

export { SVGWave2 }