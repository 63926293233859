import * as React from 'react';
// import "./care.css";

// https://thenounproject.com/icon/quality-3152755/
const SvgCare = ({ }) => {

    return (

        <svg width="100%" height="100%" version="1.1" viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
            <g fill="currentColor">
                <path d="m575.38 317.94-1.0898-0.94531v-0.003906c-8.0312-6.5898-18.348-9.7266-28.691-8.7227-10.34 1.0039-19.863 6.0625-26.48 14.074-20.555 25.004-55.363 67.012-65.828 79.609l-67.531 16.008-6.9141-7.6719 45.605-18.562v-0.003906c9.0586-3.7227 16.379-10.723 20.508-19.605 4.125-8.8828 4.75-18.996 1.75-28.32-3-9.375-9.4922-17.238-18.133-21.957-8.6445-4.7188-18.77-5.9258-28.277-3.3789l-112.29 28.18c-8.7852 2.0781-16.816 6.5547-23.207 12.926l-0.75781 0.80469-97.227 113.05c-4.9531 5.6875-4.5977 14.25 0.80469 19.512l79.895 79.988c2.7031 2.6953 6.3633 4.2109 10.18 4.2148h0.99609c4.1523-0.27344 7.9766-2.3477 10.465-5.6836l43.285-57.398 153.02-19.371c11.543-1.4961 22.09-7.3164 29.504-16.289l85.906-107.12c6.1602-7.875 9.0273-17.828 8.0039-27.77-1.0234-9.9453-5.8633-19.102-13.496-25.555zm-17.051 35.613-85.527 106.51c-2.7617 3.293-6.6719 5.4062-10.938 5.918l-159.03 20.27c-3.7773 0.51562-7.1914 2.5312-9.4688 5.5898l-37.18 49.062-58.91-58.867 88.18-102.43c2.6406-2.5039 5.9141-4.2422 9.4727-5.0195l112.81-28.414-0.003906-0.003907c4.9766-1.3203 10.125 1.4297 11.793 6.3008 1.7383 5.0273-0.73047 10.539-5.6367 12.598l-64.832 26.332c-4.3906 1.7031-7.6523 5.4766-8.7031 10.066-1.0547 4.5898 0.23828 9.4062 3.4453 12.852l26.332 29.219v0.003906c3.5391 4.0078 9.0273 5.6953 14.207 4.3555l80.508-18.941c3.0234-0.69531 5.7344-2.3594 7.7227-4.7383 0 0 44.562-53.656 69.047-83.398 3.582-4.1602 9.7617-4.8828 14.207-1.6562l0.89844 0.80469c4.0664 3.3828 4.7734 9.3555 1.6133 13.594z" className="svg-care-elem-1"></path>
                <path d="m551.51 251.92c-9.4727-47.359-43.855-77.621-87.375-77.621v0.003907c-22.98 0.79687-45.238 8.1953-64.125 21.309-22.023-14.445-43.711-21.785-64.738-21.785-43.57 0-77.668 30.691-87.141 78.238-7.3867 38.738 3.8359 81.789 30.832 118.11l23.062-17.145c-22.445-29.883-31.777-64.738-25.902-95.426 6.7734-33.957 29.457-55.031 59.148-55.031 17.191 0 36.609 7.625 56.121 22.117 5.0508 3.7891 11.996 3.7891 17.047 0 19.32-14.207 38.598-21.645 55.691-21.645 29.695 0 52.379 20.934 59.199 54.508v0.003906c4.5195 27.203-0.85938 55.129-15.152 78.711l24.957 14.207c17.711-29.598 24.227-64.562 18.375-98.555z" className="svg-care-elem-2"></path>
            </g>
        </svg>
    )
}

export { SvgCare }

